<template>
  <div class="AccessVisualizationClientDocumentAskingProfile">

    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>

    <CRow class="mb-3">
      <CCol>
        <h1><CBadge color="primary"><CIcon name="cil-center-focus" size="lg"/>  Visualisation - Que voit mon client ?</CBadge> </h1>
        <h1 class="text-dark"> Demande de document - {{documentAsking.name}} </h1>
      </CCol>
    </CRow>

    <CCard class="mt-3">
      <CCardBody>
        <CRow class="mb-2">
          <CCol>
            <strong style="font-size: 1.3em;"> Statut de la demande  :<br>
              <span v-if="documentAsking.state == 'CREATED'" class="text-primary" style="vertical-align: middle;">
                <CIcon name="cil-pencil" size="lg"/>
                <span class="ml-1">Documents à charger</span>
              </span>
              <span v-if="documentAsking.state == 'TRANSFERED'" class="text-dark" style="vertical-align: middle;">
                <CIcon name="cil-clock" size="lg"/>
                <span class="ml-1">En cours de récupération et de validation</span>
              </span>
              <span v-if="documentAsking.state == 'REFUSED'" class="text-danger" style="vertical-align: middle;">
                <CIcon name="cil-x-circle" size="lg"/>
                <span class="ml-1">Documents incomplets, veuillez modifier vos documents</span>
              </span>
              <span v-if="documentAsking.state == 'VALIDATED'" class="text-success" style="vertical-align: middle;">
                <CIcon name="cil-check-circle" size="lg"/>
                <span class="ml-1">Documents récupérés et validés</span>
              </span>
            </strong>
          </CCol>
        </CRow>

        <CRow v-if="documentAsking.state == 'REFUSED'">
          <CCol>
            <em class="text-danger"> {{documentAsking.refused_message}} </em>
          </CCol>
        </CRow>

        <CRow class="mt-3">
          <CCol>
            <span>Mission : </span>
            <strong> {{ documentAsking.mission.name }} </strong>
          </CCol>
        </CRow>


        <CRow>
          <CCol>
            <span>Description :
              <em v-if="documentAsking.description">
                {{ documentAsking.description }}
              </em>
              <em v-else>
                Aucune
              </em>
             </span>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <CCard>
      <CCardHeader>
        <strong>Documents</strong>
      </CCardHeader>
      <CCardBody>
        <CDataTable
          :items="documentsRender"
          :fields="documentsRenderFields"
          :noItemsView='{ noResults: "Aucun document", noItems: "Aucun document" }'
        >
        <!--@row-clicked="goToMissionPage"-->
        <template #file_size="{item}">
          <td>
            {{ sizeFormatter(item.file_size) }}
          </td>
        </template>

        <template #actions="{item}">
          <td class="text-right">
            <CButton
              class="ml-2"
              square
              size="sm"
              @click="downloadClientDocument(item.id, item.file_name)">
              <CIcon class="text-dark" name="cil-cloud-download"/>
            </CButton>
            <CButton
              class="ml-2"
              square
              size="sm"
              :disabled="true"
              @click="openModifyDocumentModal(item)">
              <CIcon class="text-primary" name="cil-pencil"/>
            </CButton>
            <CButton
              class="ml-2"
              square
              size="sm"
              :disabled="true"
              @click="openDeleteDocumentModal(item)">
              <CIcon class="text-danger" name="cil-trash"/>
            </CButton>
          </td>
        </template>

        <template #no-items-view>
          <CRow>
            <CCol class="text-center">
              Aucun document chargé
            </CCol>
          </CRow>
        </template>

        </CDataTable>
      </CCardBody>
    </CCard>


  </div>
</template>

<script>
import { APIUserConnected } from '@/api/APIUserConnected'
import renderMixins from '@/mixins/renderMixins'

import { fileOperations } from '@/mixins/utilMixins'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiUserConnected = new APIUserConnected()

export default {
  name: 'AccessVisualizationClientDocumentAskingProfile',
  components: {
    Loading,
  },
  mixins: [
    renderMixins,
    fileOperations
  ],
  data: function () {
    return {

      documentAsking: {
        name: '',
        description: '',
        state: 'CREATED',
        mission: {
          name: ''
        }
      },
      isDocumentAskingLoading: false,

      // ------------- DOCUMENTS -----------
      documents: [],
      documentsRender: [],
      documentsRenderFields: [
        { key: "file_name", label: "Nom", tdClass: 'ui-helper-center', sortable: true},
        { key: "file_size", label: "Taille", tdClass: 'ui-helper-center', sortable: true},
        { key: "actions", label: "", tdClass: 'ui-helper-center', sortable: true},
      ],
      isAllDocumentsLoading: false,
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isDocumentAskingLoading || this.isAllDocumentsLoading) {
        return true
      }
      return false
    },
    isDocumentAskingValidated () {
      if (this.documentAsking.state == 'VALIDATED') {
        return true
      }
      return false
    }
  },
  created: function() {
    this.getDocumentAsking()
    this.getAllDocuments()
  },
  watch: {
    documents: function (newDocuments) {
      if (newDocuments.length == 0) {
        this.documentsRender = []
      }
      else {
        var final_array = []

        for (var i = 0; i < newDocuments.length; i++) {
          final_array.push(
            {
              'id': newDocuments[i].id,
              'file_name': newDocuments[i].file_name,
              'file_size': newDocuments[i].file_size,
            }
          )
        }
        this.documentsRender = final_array
      }
    }
  },

  methods: {
    // ----------------- GETTERS ---------------------------------------
    getDocumentAsking () {
      this.isDocumentAskingLoading = true
      apiUserConnected.getDocumentAsking(this.token, this.$route.params.id)
      .then((result) => {
        this.documentAsking = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isDocumentAskingLoading = false
      })
    },

    getAllDocuments () {
      this.isAllDocumentsLoading = true
      apiUserConnected.getAllDocuments(this.token, this.$route.params.id)
      .then((result) => {
        this.documents = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllDocumentsLoading = false
      })
    },

    // --------------- DOCUMENTS ----------------------
    downloadClientDocument (document_id, document_name) {
      apiUserConnected.downloadClientDocument(this.token, document_id)
      .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', document_name); //or any other extension
          document.body.appendChild(link);
          link.click();
        }, () => {
          this.$store.commit('openGlobalErrorModal')
        })
    },
  }
}
</script>
<style>
.no-border-table td {
  border-top: None;
}

.no-border-table th {
  border-top: None;
  border-bottom: None !important;
}


.no-margin-bottom-table {
  margin-bottom: 0px !important;
}
</style>
